import firebase from 'firebase/app'
import store from '@/store'

export default async function getSingleData(payload) {
  const { collection } = payload
  let dataDic = {}
  try {
    const uid = store.getters['userBasic/uid']

    if (collection === 'user') {
      await firebase.firestore().collection(collection).doc(uid)
        .get()
        .then(doc => {
          const d = doc.data()
          if (d) { dataDic = d }
        })
    } else {
      await firebase.firestore().collection(collection)
        .where('uid', '==', uid)
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              dataDic = doc.data()
            })
          }
        })
    }

    const response = {
      status: 'success',
      data: dataDic,
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
